@font-face {
  font-family: 'uni-thin'; 
  src: url('../assets/Fonts/Uni\ Sans\ Thin.otf');
}

.card-img {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
    width: 100%;
  position: relative;
}

.card-img:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container-img {
    padding: 2px 16px;
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 4px;
    font-size: 26px;
    background-color: rgb(0 0 0 / 48%);

}
.container-img h4{
    font-size: 42px;
    color: white;
        font-family: "uni-thin";
    font-weight: 100;
}
.container-img p{
    font-size: 32px;
    color: white;
        font-family: "uni-thin";
    font-weight: 100;
}

.swiper-next{
      position: absolute;
    bottom: -9px;
    right: 23px;
    background: none;
    border: none;
    color: white;
    font-family: 'Mont-Light';
    font-size: 20px;
}
.swiper-prev{
      position: absolute;
    bottom: -9px;
    left: 23px;
    background: none;
    border: none;
    color: white;
    font-family: 'Mont-Light';
    font-size: 20px;
}
@media screen and (max-width: 1150px) {
.card-img {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
    width: 100%;
  position: relative;
}
 

}
@media screen and (max-width: 850px) {
.card-img {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
    width: 100%;
  position: relative;
}
}
@media screen and (max-width: 650px) {
.card-img {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
    width: 100%;
  position: relative;
}
}
