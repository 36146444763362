

@media screen and (max-width: 450px) {
.det-page-second-topText {
    margin-bottom: 2rem;
}
.det-page-h1-span {
    font-size: 29px;
}

.det-page-h1-span2 {
    font-size: 29px;
}
.det-page-paragraph {
    font-size: 15px;
    padding: 22px 0px;
}
.nav-tabs {
    display: flex;
    justify-content: center;
    border: none;
    font-family: "Mont-light";
}
.det-page-sect-paymentPlan {
    margin: 10px;
    padding: 10px;
}
.det-page-Plan-text {
    font-weight: 100;
    font-size: 31px;
    color: #c38b74;
    font-family: "uni-thin";
    padding: 10px 0px;
    margin-top: 20px;
}

.det-page-Payment-text {
    font-weight: 100;
    color: white;
    font-family: "uni-thin";
    padding: 10px 0px;
    font-size: 30px;
    margin-top: 10px;
}

p.det-page-Payment-middletext-p {
    padding: 10px 0px;
    font-size: 19px;
    color: white;
    font-family: 'Mont-Light';
}
.det-page-box-3 h1, .det-page-box-47 h1, .det-page-box-50 h1 {
    color: white;
    font-size: 41px;
    margin-top: 10px;
    font-weight: 800;
    font-family: 'Mont-Light';
}
.det-page-box-3 p, .det-page-box-47 p, .det-page-box-50 p {
    color: white;
    font-size: 13px;
    margin: 7px 0px;
    font-weight: 100;
    font-family: 'Mont-Light';
}
.det-developer-txt {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 100;
    font-size: 20px;
    padding: 0px 17px;
    font-family: 'Mont-Light';
}
.det-developer-section-part-b {
    display: block;
    padding: 30px 15px;
}
h1.det-page-brouchre-h1 {
    padding: 11px 10px;
    font-size: 30px;
    font-weight: 100;
    font-family: 'uni-thin';
    color: white;
}
.det-page-tabs-card {
    padding: 1px;
    margin: 13px;
    display: block;
}
.det-page-brouchre-span {
    padding: 11px 0px;
    font-size: 29px;
    font-weight: 100;
    font-family: 'uni-thin';
    color: #c38b74;
}
p.det-page-brouchre-p {
    padding: 2px;
    font-size: 17px;
    font-weight: 100;
    font-family: 'Mont-Light';
    color: white;
}
.det-page-brochure-content {
    background: linear-gradient(45deg, #d0e4f7 0%, #474143 0%, #302c2d 53%, #302c2d 78%, #302c2d 100%);
    text-align: center;
    width: 93%;
    margin: auto;
    padding: 30px 5px;
    margin-top: 40px;
    border-radius: 26px;
}
.Det-page-developers {
    display: none;

    justify-content: center;
}
}