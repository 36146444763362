
@font-face {
  font-family: 'Mont-Light';
  src: url('../assets/Fonts/Montserrat-Light.ttf');
}
@font-face {
  font-family: 'Mont-Medium';
  src: url('../assets/Fonts/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Mont-Regular';
  src: url('../assets/Fonts/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'uni-thin'; 
  src: url('../assets/Fonts/Uni\ Sans\ Thin.otf');
}
@font-face {
  font-family: 'uni-Regular';
  src: url('../assets/Fonts/Uni\ Sans\ Heavy.otf');
}

.card {
  width: 100%;
  margin: auto;
  border: 1px solid #ccc;
  overflow: hidden;
  margin-bottom: 20px;

}

.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-body {
    padding: 0px 8px 2px 8px;
        text-align: left;

}

.card-title {
    font-size: 24px;
    font-weight: 100;
    margin: 0px;
    margin: 15px 0px 0px 0px;
    color: #b9b8b8;
    font-family: "uni-thin";
}

.card-text {
    margin-top: 45px;
    font-size: 18px;
    color: #b9b8b8;
    font-family: "uni-thin";
    font-weight: 100;

    }

.card-footer {
  background-color: #f8f9fa;
  padding: 10px 20px;
}
.card-content-upper {
    display: flex;
    padding: 10px 0px;
    justify-content: space-around;
    width: 50%;

}
.card-content-upper p{
    font-size: 12px;
    color: #b9b8b8;
    font-family: "uni-thin";
    font-weight: 100;
    padding: 1px 0px;
}
.card-price{
    font-size: 16px;
    font-weight: 100;
    border-top: 1px solid grey;
        color: white;
            padding-bottom: 1px;

}
.card-price p{
  font-size: 28px;
    color: #b9b8b8;
    font-family: "uni-thin";
    font-weight: 100;  border-bottom: 1px solid grey;
  padding: 10px 0px;

}
.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-cons-bottom {
    display: flex;
    justify-content: space-between;
}

.icons i {
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
}

.icons i:hover {
  color: #007bff;
}
.whatsapp img{
    width: 20px;
    height: 20px;
}
/* svg.svg-inline--fa.fa-envelope {
    color: #c38b74;
    font-size: 18px;
    margin-top: 4px;
} */
svg.svg-inline--fa.fa-location-dot {
    padding: 0px 5px;
}
/* svg.svg-inline--fa.fa-phone {
      color: #c38b74;
    font-size: 18px;
    margin-top: 4px;
} */
svg.svg-inline--fa.fa-map-location-dot{
        color: #c38b74;
    font-size: 14px;
    margin-top: 4px;
}
svg.svg-inline--fa.fa-bath{
          color: #c38b74;
    font-size: 14px;
    margin-top: 4px;
}

svg.svg-inline--fa.fa-bed{
            color: #c38b74;
    font-size: 14px;
    margin-top: 4px;
}

svg.svg-inline--fa.fa-location-dot{
              color: #c38b74;
    font-size: 14px;
    margin-top: 4px;
}

.card-cons-bottom .call{
  width: 33%;
    text-align: center;

    border-right: 1px solid white;

}
.card-cons-bottom .mesg{
  border-right: 1px solid white;
    text-align: center;
  width: 33%;
}
.card-cons-bottom .whatsapp{
  text-align: center;
  width: 33%;
}
.swiper-next {
position: absolute;
    bottom: -15px;
right: 40px;
background:none;
border:none;
color: white;
font-size:20px;
font-family: 'Mont-Light';
font-weight: 100;

}
.swiper-prev{
  
position: absolute;
    bottom: -15px;
left: 35px;
background:none;
border:none;
color: white;
font-size:20px;  
font-family: 'Mont-Light';
font-weight: 100;
} 

.whatsapp{
  color: white;
  font-size: 22px;
}
.whatsapp:hover{
  color: rgba(48, 130, 48, 0.692);
}
.envelop-icon{
  color: white;
  font-size: 20px;
      margin-top: 5px;

}
.envelop-icon:hover{
  color: wheat;
  font-size: 20px;
}
.phone-icon{
  color: white;
  font-size: 20px;
  margin-top: 5px;

}
.phone-icon:hover{
  color: rgba(82, 124, 209, 0.622);
  font-size: 20px;
}