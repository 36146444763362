.banner-card-box {
  background: linear-gradient(
    45deg,
    #d0e4f7 0%,
    #474143 0%,
    #302c2d 53%,
    #302c2d 78%,
    #302c2d 100%
  );
  width: 170px;
  height: 128px;
  margin-bottom: 10px;
  border-radius: 9px;
  text-align: center;
  padding: 37px 0px;
  color: white;
}
.banner-card-box:hover {
  background: #c38b74;
}
.banner-box-flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  transform: translateY(-60px);
}
.banner-box-row1 {
  display: flex;
  justify-content: space-around;
  width: 50%;
  flex-wrap: wrap;
}
.banner-box-row2 {
  display: flex;
  justify-content: space-around;
  width: 50%;
  flex-wrap: wrap;
}
